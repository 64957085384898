import Loading from 'components/UI/Loading/Loading';
import { useInitializationApplication } from 'hooks/useInitializationApplication';
import { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes';

const ReportMainPage = lazy(() => import('pages/reports/main'));
const DownloadPage = lazy(() => import('pages/reports/download'));

const MainContent: React.FC = () => {
  const { isInit, isError } = useInitializationApplication();

  if (!isInit && !isError) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={routes.report.download} component={DownloadPage} exact />
        <Route path={routes.report.default} component={ReportMainPage} />
        <Route exact path={routes.home}>
          <Redirect to={routes.report.default} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default MainContent;
