import { styled } from '@mui/material/styles';
import Header from 'components/Layout/Header/Header';
import { Fragment } from 'react';
import SideDrawer from './SideDrawer/SideDrawer';

const Main = styled('main')(() => ({
  marginTop: 60,
  marginLeft: 100,
  padding: '22px',
  height: 'calc(100% - 60px)',
  overflowY: 'hidden',
  boxSizing: 'border-box',
  boxShadow: '0 0 16px rgba(0,0,0,0.04)',
  borderTopLeftRadius: '10px',
  backgroundColor: '#FFFFFF',
}));

const Layout: React.FC = (props) => {
  return (
    <Fragment>
      <Header />
      <SideDrawer />
      <Main>{props.children}</Main>
    </Fragment>
  );
};

export default Layout;
