import api from 'core/api/api.config';

export const fetchApplicationConfiguration = async () => {
  const response = await api.get<AppConfiguration>('/spaConfiguration');

  return response.data;
};

export interface AppConfiguration {
  mui: string;
}
