const internalRoutes = {
  default: '/',
  report: {
    default: '/report',
  },
};

const routes = {
  home: internalRoutes.default,
  report: {
    default: internalRoutes.report.default,
    download: `${internalRoutes.report.default}/download`,
    condensed: `${internalRoutes.report.default}/condensed`,
  },
};

export default routes;
