export const getInitials = (fullname: string) => {
  if (!fullname) {
    return '';
  }

  let names = fullname.split(' ');
  let initials = '';

  if (names.length > 2) {
    names = names.slice(0, 2);
  }
  names.forEach((n) => {
    initials += n.charAt(0);
  });

  return initials;
};
