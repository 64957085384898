import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Loading: React.FC = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <CircularProgress />
    </Stack>
  );
};

export default Loading;
