import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface PaletteColor {
    containedHoverBackground: string;
    outlinedHoverBackground: string;
    outlinedRestingBackground: string;
    content?: string;
    background?: string;
    backgroundLight?: string;
  }

  interface SimplePaletteColorOptions {
    containedHoverBackground: string;
    outlinedHoverBackground: string;
    outlinedRestingBackground: string;
    content?: string;
    background?: string;
    backgroundLight?: string;
  }

  interface Palette {
    gradients: {
      primary: {
        from: string;
        to: string;
      };
      secondary: {
        from: string;
        to: string;
      };
      greyScale: {
        from: string;
        to: string;
      };
    };
    outlineBorder: string;
    inputLine: string;
    backdropOverlay: string;
    ratingActive: string;
    snackbarBackground: string;
    headerBackground: string;
  }

  interface PaletteOptions {
    gradients: {
      primary: {
        from: string;
        to: string;
      };
      secondary: {
        from: string;
        to: string;
      };
      greyScale: {
        from: string;
        to: string;
      };
    };
    outlineBorder: string;
    inputLine: string;
    backdropOverlay: string;
    ratingActive: string;
    snackbarBackground: string;
    headerBackground: string;
  }
}

const theme = createTheme({
  spacing: [0, 2, 4, 8, 16, 32, 64, 96],
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h1: {
      fontSize: 96,
      fontWeight: 300,
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
    },
    h5: {
      fontSize: 24,
      fontWeight: 500,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#005BD4',
      light: '#70BFFF',
      dark: '#003765',
      contrastText: '#FFFFFF',
      containedHoverBackground: 'rgba(0, 91, 212, 1)',
      outlinedHoverBackground: 'rgba(0, 91, 212, 0.08)',
      outlinedRestingBackground: 'rgba(0, 91, 212, 0.5)',
    },
    secondary: {
      main: '#A0ACBD',
      light: '#E3E6EB',
      dark: '#606771',
      contrastText: '#FFFFFF',
      containedHoverBackground: 'rgba(160, 172, 189, 1)',
      outlinedHoverBackground: 'rgba(160, 172, 189, 0.08)',
      outlinedRestingBackground: 'rgba(160, 172, 189, 0.5)',
    },
    error: {
      main: '#F44336',
      light: '#f88078',
      dark: '#7a3530',
      contrastText: '#FFFFFF',
      containedHoverBackground: '#ab2f26',
      outlinedHoverBackground: '#feeceb',
      outlinedRestingBackground: 'rgba(244, 67, 54, 0.1)',
      content: 'rgba(244, 67, 54, 1)',
      background: 'rgba(244, 67, 54, 1)',
      backgroundLight: '#FFCCCB',
    },
    warning: {
      main: '#ED6C02',
      light: '#FFB547',
      dark: '#C77700',
      contrastText: '#FFFFFF',
      containedHoverBackground: '#a64c01',
      outlinedHoverBackground: '#fdf0e5',
      outlinedRestingBackground: 'rgba(237, 108, 2, 0.08)',
      content: 'rgba(237, 108, 2, 1)',
      background: 'rgba(237, 108, 2, 1)',
      backgroundLight: 'rgba(240, 125, 31, 0.38)',
    },
    info: {
      main: '#2196F3',
      light: '#64b6f7',
      dark: '#64B6F7',
      contrastText: '#FFFFFF',
      containedHoverBackground: '#1769ab',
      outlinedHoverBackground: '#e8f4fe',
      outlinedRestingBackground: 'rgba(33, 150, 243, 0.1)',
      content: 'rgba(33, 150, 243, 1)',
      background: 'rgba(33, 150, 243, 1)',
      backgroundLight: '#e9f5fe',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
      contrastText: '#FFFFFF',
      containedHoverBackground: '#357b38',
      outlinedHoverBackground: '#edf7ed',
      outlinedRestingBackground: 'rgba(76, 175, 80, 0.1)',
      content: 'rgba(76, 175, 80, 1)',
      background: 'rgba(76, 175, 80, 1)',
    },
    text: {
      primary: '#14142A',
      secondary: '#40454C',
      disabled: '#606771',
    },
    action: {
      active: 'rgba(160, 172, 189, 1)',
      hover: 'rgba(160, 172, 189, 0.05)',
      hoverOpacity: 0.05,
      selected: 'rgba(160, 172, 189, 0.1)',
      selectedOpacity: 0.08,
      disabled: 'rgba(64, 69, 76, 0.3)',
      disabledBackground: 'rgba(64, 69, 76, 0.15)',
      disabledOpacity: 0.45,
      focus: 'rgba(160, 172, 189, 0.1)',
      focusOpacity: 0.1,
    },
    grey: {
      50: '#FBFCFC',
      100: '#F6F7F8',
      200: '#E3E6EB',
      300: '#D0D6DE',
      400: '#BDC5D1',
      500: '#A0ACBD',
      600: '#808A97',
      700: '#606771',
      800: '#40454C',
      900: '#202226',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    divider: '#E3E6EB',
    outlineBorder: '#FFFFFF',
    inputLine: '#FFFFFF',
    backdropOverlay: 'rgba(160, 172, 189, 0.5)',
    ratingActive: '#FDCC62',
    snackbarBackground: '#40454C',
    headerBackground: '#EAF2FB',
    gradients: {
      primary: {
        from: 'rgba(11, 98, 245, 1)',
        to: 'rgba(1, 63, 201, 1)',
      },
      secondary: {
        from: 'rgba(249, 171, 52, 1)',
        to: 'rgba(255, 230, 142, 1)',
      },
      greyScale: {
        from: 'rgba(237, 247, 248, 1)',
        to: 'rgba(160, 172, 189, 1)',
      },
    },
  },
});

// Global styles
theme.components = {
  MuiCssBaseline: {
    styleOverrides: {
      'html, body, #root': {
        height: '100%',
        maxHeight: '100%',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
        backgroundColor: theme.palette.headerBackground,
      },
      a: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
      p: {
        margin: 0,
        padding: 0,
      },
      button: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        cursor: 'pointer',
        border: 0,
        background: 'transparent',
      },
      input: {
        '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
};

export default theme;
