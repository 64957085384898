import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useState } from 'react';
import { loginRequest, msalConfig } from './auth.config';

export const msalInstance = new PublicClientApplication(msalConfig);

const AuthProvider: React.FC = (props) => {
  const [loggedIn, setIsLoggedIn] = useState(false);

  const handleResponse = () => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      setIsLoggedIn(() => false);
      msalInstance.loginRedirect(loginRequest);
    } else {
      setIsLoggedIn(() => true);
    }
  };

  msalInstance
    .handleRedirectPromise()
    .then(() => {
      handleResponse();
    })
    .catch(() => {
      msalInstance.loginRedirect(loginRequest);
    });

  return <MsalProvider instance={msalInstance}>{loggedIn ? props.children : null}</MsalProvider>;
};

export default AuthProvider;
