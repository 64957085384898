import { Avatar, styled, Tooltip } from '@mui/material';
import { getInitials } from 'helpers/userHelper/userHelper';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  textTransform: 'uppercase',
}));

const LezioAvatar: React.FC<{ name: string; imageUrl?: string; avatarSize?: number; hideTooltip?: boolean }> = (
  props,
) => {
  const tooltipTitle = props.hideTooltip ? '' : props.name;

  const avatarRendered = props.imageUrl ? (
    <Tooltip title={tooltipTitle}>
      <StyledAvatar sx={{ width: props.avatarSize, height: props.avatarSize }} alt={props.name} src={props.imageUrl} />
    </Tooltip>
  ) : (
    <Tooltip title={tooltipTitle}>
      <StyledAvatar
        sx={{
          width: props.avatarSize,
          height: props.avatarSize,
          fontSize: props.avatarSize ? `${props.avatarSize / 2}px` : 20,
        }}
      >
        {getInitials(props.name)}
      </StyledAvatar>
    </Tooltip>
  );

  return avatarRendered;
};

export default LezioAvatar;
