import { IconButton, Menu, MenuItem } from '@mui/material';
import LezioAvatar from 'components/UI/LezioAvatar/LezioAvatar';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { Fragment } from 'react';

export interface ProfileContextMenuItem {
  label: string;
  action: () => void;
}

const ProfileContextMenu: React.FC<{ menuId: string; menuItems: ProfileContextMenuItem[]; accountName?: string }> = (
  props,
) => {
  const popupState = usePopupState({ variant: 'popover', popupId: props.menuId });
  const handleItemClicked = (linkAction: () => void) => {
    popupState.close();
    linkAction();
  };

  return (
    <Fragment>
      <IconButton {...bindTrigger(popupState)}>
        <LezioAvatar name={props.accountName || ''} avatarSize={32} hideTooltip />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {props.menuItems.map((item) => (
          <MenuItem key={item.label} onClick={() => handleItemClicked(item.action)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default ProfileContextMenu;
