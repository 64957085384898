import EqualizerIcon from '@mui/icons-material/Equalizer';
import { List, ListItemIcon, ListItemText, styled } from '@mui/material';
import MuiListItem from '@mui/material/ListItem';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import routes from 'routes';

const Nav = styled('nav')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const Link = styled(NavLink)(({ theme }) => ({
  display: 'block',
  textDecoration: 'none',
  '&.active .MuiListItemIcon-root': {
    backgroundColor: theme.palette.primary.outlinedHoverBackground,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}));

const ListItem = styled(MuiListItem)(() => ({
  padding: '20px 26px',
  '& .MuiListItemIcon-root': {
    minWidth: 48,
    width: 48,
    height: 48,
    marginRight: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px',
  },
}));

const Navigation: React.FC<{ onClickLink: () => void }> = () => {
  const { t } = useTranslation();

  const menuItems = [
    {
      text: 'report:navigationButton',
      icon: <EqualizerIcon />,
      route: routes.report.default,
    },
  ];

  return (
    <Nav>
      <List sx={{ padding: 0 }}>
        {menuItems.map((menuItem) => (
          <Link key={menuItem.route} activeClassName="active" to={menuItem.route}>
            <ListItem>
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={t(menuItem.text)} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Nav>
  );
};

export default Navigation;
