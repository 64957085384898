export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_MSAL_SIGNUPSIGNIN,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_MSAL_SIGNUPSIGNIN}`,
    },
  },
  authorityDomain: `${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com`,
};

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENTID}`],
  redirectUri: process.env.REACT_APP_MSAL_REDIRECTURI,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENTID || '',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};
