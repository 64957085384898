import axios from 'axios';
import { loginRequest } from 'core/auth/auth.config';
import { msalInstance } from '../auth/AuthProvider';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    'accept-language': 'fr-CA',
  },
});

export const getAccessToken = async () => {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      throw new Error('No accounts found in MSAL instance.');
    }

    const tokenResponse = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    return tokenResponse.accessToken;
  } catch (error) {
    msalInstance.logoutRedirect();
    throw error;
  }
};

api.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();
    const configuration = { ...config };

    configuration.headers.Authorization = `Bearer ${accessToken}`;

    return configuration;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      msalInstance.logoutRedirect();
    }

    return Promise.reject(error);
  },
);

export const statusCodes = {
  ok: 200,
  created: 201,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  internalServerError: 500,
};

export default api;
