import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AuthProvider from 'core/auth/AuthProvider';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './core/i18n/i18n.config';
import theme from './core/theme/theme.config';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </CssBaseline>
  </ThemeProvider>,
  document.getElementById('root'),
);
