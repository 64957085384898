import { useQuery } from '@tanstack/react-query';
import { fetchApplicationConfiguration } from '../services/InitApplication';

export const useGetApplicationConfigurationQuery = () => {
  const results = useQuery(['initApp'], fetchApplicationConfiguration, {
    useErrorBoundary: false,
  });

  return results;
};
