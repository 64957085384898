import { useMsal } from '@azure/msal-react';
import { Box, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { ReactComponent as Logo } from 'assets/images/Logo.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ProfileContextMenu from './ProfileContextMenu/ProfileContextMenu';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.headerBackground,
  '& .MuiToolbar-root': {
    minHeight: 60,
    justifyContent: 'space-between',
  },
}));

const Header = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', height: 55 }}>
          <NavLink to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <Logo height={25} />
          </NavLink>
        </Box>
        <ProfileContextMenu
          menuId="accountMenu"
          menuItems={[{ label: t('root:logout'), action: handleLogout }]}
          accountName={account?.idTokenClaims?.name}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
