import { LicenseInfo } from '@mui/x-license-pro';
import { useGetApplicationConfigurationQuery } from 'core/query/hook/useInitApplicationQuery';
import { useEffect, useState } from 'react';

export const useInitializationApplication = () => {
  const { data: config, isError } = useGetApplicationConfigurationQuery();

  const [isInit, setIsInit] = useState<{ config: boolean }>({ config: false });

  useEffect(() => {
    if (config) {
      LicenseInfo.setLicenseKey(config?.mui || '');
      setIsInit((state) => ({ ...state, config: true }));
    }
  }, [config]);

  return {
    isInit: isInit.config,
    isError,
  };
};
