import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import organizationFr from './fr/organization.json';
import queryFr from './fr/query.json';
import reportFr from './fr/report.json';
import rootFr from './fr/root.json';

const resources = {
  fr: {
    root: rootFr,
    report: reportFr,
    organization: organizationFr,
    query: queryFr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'fr',
});

export default i18n;
