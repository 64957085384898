import CloseIcon from '@mui/icons-material/Close';
import { Grow, IconButton } from '@mui/material';
import { SnackbarProvider, closeSnackbar } from 'notistack';

interface SnackbarCloseActionProps {
  closeActionKey: number | string;
}

const SnackbarCloseAction: React.FC<SnackbarCloseActionProps> = ({ closeActionKey }) => (
  <IconButton onClick={() => closeSnackbar(closeActionKey)}>
    <CloseIcon fontSize="small" />
  </IconButton>
);

const ConfiguredSnackbarProvider: React.FC = ({ children }) => {
  const getActionComponent = (key: number | string) => {
    return <SnackbarCloseAction closeActionKey={key} />;
  };

  return (
    <SnackbarProvider
      TransitionComponent={Grow}
      maxSnack={10}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      action={getActionComponent}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ConfiguredSnackbarProvider;
