import { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { ClickAwayListener } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiIconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Navigation from '../Navigation/Navigation';

const closedDrawerWidth = 100;
const drawerWidth = 212;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.headerBackground,
  color: theme.palette.text.primary,
  border: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth,
  backgroundColor: theme.palette.headerBackground,
  color: theme.palette.text.primary,
  border: 0,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {},
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const IconButton = styled(MuiIconButton)(() => ({
  borderRadius: 0,
  height: 60,
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const SideDrawer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader />
        <Navigation onClickLink={handleDrawerClose} />
        {open && (
          <IconButton onClick={handleDrawerClose}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {!open && (
          <IconButton onClick={handleDrawerOpen}>
            <ArrowForwardIcon />
          </IconButton>
        )}
      </Drawer>
    </ClickAwayListener>
  );
};

export default SideDrawer;
