import { Mutation, MutationCache, Query, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainContent from 'MainContent';
import axios from 'axios';
import Layout from 'components/Layout/Layout';
import ConfiguredSnackbarProvider from 'contexts/SnackbarProvider';
import i18n from 'core/i18n/i18n.config';
import { enqueueSnackbar } from 'notistack';

const queryCache = new QueryCache({
  onError: (_, query: Query) => {
    const tKey = `query:${query.queryKey[0]}.fetch.error`;

    if (i18n.t(tKey) === tKey) {
      enqueueSnackbar(i18n.t('query:common.unexpectedError'), { variant: 'error' });
    }
    enqueueSnackbar(i18n.t(tKey), { variant: 'error' });
  },
});

const mutationCache = new MutationCache({
  onSuccess: (_, __, ___, mutation: Mutation<unknown, unknown, unknown, unknown>) => {
    const mutationKey = mutation.options.mutationKey;
    if (mutationKey) {
      enqueueSnackbar(i18n.t(`query:${mutationKey[0]}.success`), { variant: 'success' });
    }
  },
  onError: (error, _, __, mutation) => {
    const mutationKey = mutation.options.mutationKey;
    if (axios.isAxiosError(error)) {
      enqueueSnackbar(error.response?.data.error[0] || i18n.t('query:common.unexpectedError'), { variant: 'error' });
    } else if (mutationKey) {
      enqueueSnackbar(i18n.t(`query:${mutationKey[0]}.error`), { variant: 'error' });
    } else {
      enqueueSnackbar(i18n.t(`query:ccommon.unexpectedError`), { variant: 'error' });
    }
  },
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: false,
      refetchOnWindowFocus: false, // default: true
    },
  },
  queryCache,
  mutationCache,
});

const App: React.FC = () => {
  return (
    <ConfiguredSnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <MainContent />
        </Layout>
      </QueryClientProvider>
    </ConfiguredSnackbarProvider>
  );
};

export default App;
